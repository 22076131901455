import {
  nrkLogoNrk,
  nrkChevronDown,
  nrkUserNotloggedin,
  nrkSearch
} from '@nrk/core-icons'

/**
 * @typedef {Object} MastheadOptions
 * @property {boolean} [dark=false]
 * @property {boolean} [login=true]
 * @property {boolean} [search=true]
 * @property {boolean} [minimal=false]
 * @property {string} [nonce]
 * @property {JumpAnchorManualOptions | JumpAnchorAutoOptions | false} [jumpAnchor=false]
 */

/**
 * @typedef {Object} JumpAnchorAutoOptions
 * @property {'auto'} strategy
 * @property {never} [jumpToId='']
 */

/**
 * @typedef {Object} JumpAnchorManualOptions
 * @property {'manual'} strategy
 * @property {`#${string}`} jumpToId MUST include `#`
 */

/**
 * Masthead
 * @param {MastheadOptions} [options]
 * @returns {string} raw html markup
 */
export function Masthead (options) {
  const { dark = false, login = true, search = true, minimal = false, jumpAnchor = false } = options || {}

  let hideMasthead = false

  if (typeof window !== 'undefined') {
    const searchParams = new URLSearchParams(window.location.search)
    const headerParam = searchParams.get('header')
    hideMasthead = headerParam === 'off'
  }

  return /*html*/ `<header class="nrk-masthead lp_masthead${dark ? ' nrk-masthead--dark' : ''}${
    minimal ? ' nrk-masthead--minimal' : ''
  }" ${hideMasthead ? 'hidden' : ''} >
      ${getJumpAnchor(jumpAnchor)}
      <a class="nrk-masthead__logo" href="https://www.nrk.no/" aria-label="NRK">${nrkLogoNrk}</a>
      <button class="nrk-masthead__bars" aria-label="Meny" aria-expanded="false">
        <svg aria-hidden="true" focusable="false" width="24" height="24" viewBox="0 0 24 24">
          <g fill-rule="evenodd">
            <rect width="20" height="2" x="2" y="4" />
            <rect width="20" height="2" x="2" y="11"/>
            <rect width="20" height="2" x="2" y="18"/>
          </g>
        </svg>
      </button>

      <nav class="nrk-masthead__desktop" hidden>
        <div class="nrk-masthead__apps">
          <ul>
            <li><a href="https://tv.nrk.no/">NRK TV</a></li>
            <li><a href="https://radio.nrk.no/">NRK Radio</a></li>
            <li><a href="https://nrksuper.no">NRK Super</a></li>
            <li><a href="https://p3.no">NRK P3</a></li>
            <li><a href="https://yr.no">Yr</a></li>
          </ul>
        </div>

        <ul class="nrk-masthead__promo">
          <li><a href="https://www.nrk.no/nyheter/">Nyheter</a></li>
          <li><a href="https://www.nrk.no/sport/">Sport</a></li>
          <li><a href="https://www.nrk.no/kultur/">Kultur</a></li>
          <li><a href="https://www.nrk.no/video/humor/">Humor</a></li>
          <li><a href="https://www.nrk.no/distrikt/">Distrikt</a></li>
        </ul>
        <button class="nrk-masthead__more" aria-expanded="false" aria-controls="nrk-masthead__mega">
          Mer 
          <span class="nrk-masthead__more-icon">${nrkChevronDown}<span>
        </button>
        <div class="nrk-masthead__mega" id="nrk-masthead__mega" hidden>
          <div class="nrk-masthead__wrapper">
            <div class="nrk-masthead__sections">
              <div class="nrk-masthead__section">
                <h2>Nyheter</h2>
                <ul>
                  <li><a href="https://www.nrk.no/nyheter/">Nyhetssenter</a></li>
                  <li><a href="https://www.nrk.no/norge/">Norge</a></li>
                  <li><a href="https://www.nrk.no/urix/">Urix</a></li>
                  <li><a href="https://www.nrk.no/debatten/">Debatten</a></li>
                  <li><a href="https://www.nrk.no/kultur/">Kultur</a></li>
                  <li><a href="https://www.nrk.no/livsstil/">Livsstil</a></li>
                  <li><a href="https://www.nrk.no/viten/">Viten</a></li>
                  <li><a href="https://www.nrk.no/dokumentar/">Dokumentar</a></li>
                  <li><a href="https://www.nrk.no/ytring/">Ytring</a></li>
                  <li><a href="https://www.nrk.no/sapmi/">NRK Sápmi</a></li>
                  <li><a href="https://www.nrk.no/kvensk/">NRK Kvensk</a></li>
                </ul>
              </div>
              <div class="nrk-masthead__section nrk-masthead__section--narrow">
                <h2>Sport</h2>
                <ul>
                  <li><a href="https://www.nrk.no/sport/">Sportsnyheter</a></li>
                  <li><a href="https://resultater.nrk.no/">Livesport</a></li>
                  <li><a href="https://www.nrk.no/sport/sendeplan/">Sendeplan</a></li>
                </ul>
              </div>
              <div class="nrk-masthead__section">
                <h2>Distrikt</h2>
                <ul>
                  <li><a href="https://www.nrk.no/sorlandet/">Agder</a></li>
                  <li><a href="https://www.nrk.no/stor-oslo/">Akershus</a></li>
                  <li><a href="https://www.nrk.no/buskerud/">Buskerud</a></li>
                  <li><a href="https://www.nrk.no/tromsogfinnmark/">Finnmark</a></li>
                  <li><a href="https://www.nrk.no/innlandet/">Innlandet</a></li>
                  <li><a href="https://www.nrk.no/mr/">M&oslash;re og Romsdal</a></li>
                  <li><a href="https://www.nrk.no/nordland/">Nordland</a></li>
                  <li><a href="https://www.nrk.no/stor-oslo/">Oslo</a></li>
                  <li><a href="https://www.nrk.no/rogaland/">Rogaland</a></li>                  
                  <li><a href="https://www.nrk.no/vestfoldogtelemark/">Telemark</a></li>
                  <li><a href="https://www.nrk.no/tromsogfinnmark/">Troms</a></li>
                  <li><a href="https://www.nrk.no/trondelag/">Tr&oslash;ndelag</a></li>
                  <li><a href="https://www.nrk.no/vestfoldogtelemark/">Vestfold</a></li>
                  <li><a href="https://www.nrk.no/vestland/">Vestland</a></li>
                  <li><a href="https://www.nrk.no/ostfold/">&Oslash;stfold</a></li>
                </ul>
              </div>
              <div class="nrk-masthead__section nrk-masthead__section--narrow">
                <h2>Temasider</h2>
                <ul>
                  <li><a href="https://www.nrk.no/video/humor/">NRK Humor</a></li>
                  <li><a href="https://www.nrk.no/mat/">NRK Mat</a></li>
                  <li><a href="https://www.nrk.no/skole/">NRK Skole</a></li>
                  <li><a href="https://www.nrkbeta.no/">NRKbeta</a></li>
                </ul>
                <h2>Info</h2>
                <ul>
                  <li><a href="https://hjelp.nrk.no/">Hjelp</a></li>
                  <li><a href="https://www.nrk.no/informasjon/">Informasjon</a></li>
                  <li><a href="https://info.nrk.no/karriere/">Karriere</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="nrk-masthead__user-and-search-wrapper">
          ${
            login
              ? `<div class="nrk-masthead__user nrk-masthead__user--desktop">
                <a href="https://www.nrk.no/logginn?context=nrkno-masthead-login" hidden>
                  ${nrkUserNotloggedin}
                  <span>Logg på</span>
                </a>
              </div>`
              : ''
          }
          ${
            search
              ? `<div class="nrk-masthead__search">
                  <form class="nrk-masthead__search-form" role="search" action="https://www.nrk.no/sok/">
                    <input type="search" name="q" aria-label="S&oslash;k i hele nrk-universet" placeholder="S&oslash;k" autoComplete="off">
                    <button type="submit" aria-label="S&oslash;k">${nrkSearch}</button>
                  </form>
                </div>`
              : ''
          }
        </div>
      </nav>
      ${
          login
            ? `<div class="nrk-masthead__user nrk-masthead__user--mobile">
                      <a href="https://www.nrk.no/logginn?context=nrkno-masthead-login" hidden>
                        ${nrkUserNotloggedin}
                        <span>Logg på</span>
                      </a>
                    </div>`
            : ''
        }
      <script async src="/logginn.js"></script>
  </header>`
}

/**
 * Returns a jump to content anchor.
 * Note that strategy `auto` requires JavaScript.
 * @param {JumpAnchorManualOptions | JumpAnchorAutoOptions | false} options
 * @returns {string}
 */
function getJumpAnchor (options) {
  if (!options) {
    return ''
  }

  if (options.strategy === 'auto') {
    return '<a id="nrk-masthead__jump-anchor" class="nrk-masthead__jump-anchor nrk-masthead__jump-anchor--disabled" href="#innhold">Hopp til innhold</a>'
  }

  return `<a id="nrk-masthead__jump-anchor" class="nrk-masthead__jump-anchor nrk-masthead__jump-anchor--enabled" href="${options.jumpToId}">Hopp til innhold</a>`
}
